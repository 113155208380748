import { __assign } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { Spin } from 'antd';
import React from 'react';
import styles from './Loader.module.css';
import { useTranslation } from 'react-i18next';
var Loader = function (_a) {
    var style = _a.style;
    var t = useTranslation().t;
    Loader.defaultProps = { style: {} };
    return (_jsx("div", __assign({ className: styles.loader, style: style }, { children: _jsx(Spin, { tip: t('Loading'), size: "large" }) })));
};
export default Loader;

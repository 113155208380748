import { __assign, __awaiter, __generator } from "tslib";
import { jsx as _jsx } from "react/jsx-runtime";
import { message } from 'antd';
import axios from 'axios';
import { fetchSignInMethodsForEmail, sendEmailVerification, } from 'firebase/auth';
import jwt_decode from 'jwt-decode';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useIdToken } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { analyticsEvent } from '@careerflow/common-components/dist/utils/Analytics';
import { instituteAppApiPath, miscApiPath, stripeCheckoutApiPath, userDetailApiPath, userRoleApiPath, } from '../utils/apiPaths';
import { auth, logInWithEmailAndPassword, logout, registerWithEmailAndPassword, signInWithGoogle, } from '../utils/firebase/firebaseIndex';
import { isPublicRoute, isShouldLogout } from '../utils/publicRoutes';
import { useTranslation } from 'react-i18next';
export var AppContext = createContext({
    isExtensionInstalled: false,
    setIsExtensionInstalled: function () { return null; },
    upgrade: false,
    setUpgrade: function () { return null; },
    signInWithGoogleCall: function () { return null; },
    userInfo: {},
    appConfig: {},
    authToken: null,
    user: {},
    isMobile: false,
    newUser: false,
    setNewUser: function () { return null; },
    setUserInfo: function () { return null; },
    newUserSignupComplete: false,
    loading: false,
    handleAcceptInviteByLink: function () { return null; },
    institute: null,
    getUserDetails: function () { return null; },
    signinWithEmailAndPassword: function (email, password) { return null; },
    signupWithEmailAndPassword: function (email, password, fname, lname, src, setLoading, cb) { return null; },
});
export var sendTokenToChromeExtension = function (_a) {
    var user = _a.user;
    return new Promise(function (resolve, reject) {
        var _a, _b;
        (_b = (_a = window.chrome) === null || _a === void 0 ? void 0 : _a.runtime) === null || _b === void 0 ? void 0 : _b.sendMessage(process.env.REACT_APP_EXTENSION_ID, { user: user }, function (response) {
            var _a, _b;
            var lastError = (_b = (_a = window.chrome) === null || _a === void 0 ? void 0 : _a.runtime) === null || _b === void 0 ? void 0 : _b.lastError;
            if (response && !response.success) {
                reject(lastError);
                return response;
            }
            else {
                resolve('Done');
            }
        });
    });
};
function UserProvider(_a) {
    var _this = this;
    var children = _a.children;
    var _b = useIdToken(auth), user = _b[0], loading = _b[1];
    var _c = useState(false), upgrade = _c[0], setUpgrade = _c[1];
    var _d = useState(), institute = _d[0], setInstitute = _d[1];
    var _e = useState(window.innerWidth <= 768), isMobile = _e[0], setIsMobile = _e[1];
    var _f = useState(false), newUserSignupComplete = _f[0], setNewUserSignupComplete = _f[1];
    var _g = useState(false), newUser = _g[0], setNewUser = _g[1];
    var _h = useState(null), authToken = _h[0], setAuthToken = _h[1];
    var _j = useState({}), appConfig = _j[0], setAppConfig = _j[1];
    var _k = useState(false), isExtensionInstalled = _k[0], setIsExtensionInstalled = _k[1];
    var _l = useState({
        fname: '',
        lname: '',
        email: '',
        username: '',
        profilePic: '',
        jobBoardOnboarding: null,
        questionnaire: {},
        removedSkills: [],
        addedSkills: [],
        phoneNo: '',
        gender: '',
        dob: '',
        invites: {},
        userType: '',
        country: '',
        webAppLanguage: '',
    }), userInfo = _l[0], setUserInfo = _l[1];
    var navigate = useNavigate();
    var i18n = useTranslation().i18n;
    useEffect(function () {
        var _a, _b, _c, _d, _e;
        var instituteSource = Object.keys((_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites) !== null && _a !== void 0 ? _a : {}).length
            ? (_b = Object.keys(userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites)) === null || _b === void 0 ? void 0 : _b[0]
            : (_e = (_d = Object.keys((_c = userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles) !== null && _c !== void 0 ? _c : {})) === null || _d === void 0 ? void 0 : _d[0]) !== null && _e !== void 0 ? _e : '';
        if (instituteSource) {
            axios
                .get("".concat(instituteAppApiPath, "/").concat(instituteSource))
                .then(function (res) {
                setInstitute(__assign({ id: instituteSource }, res.data));
            });
        }
    }, [userInfo === null || userInfo === void 0 ? void 0 : userInfo.instituteRoles, userInfo === null || userInfo === void 0 ? void 0 : userInfo.invites]);
    useEffect(function () {
        if ('webAppLanguage' in userInfo) {
            i18n.changeLanguage(userInfo === null || userInfo === void 0 ? void 0 : userInfo.webAppLanguage);
        }
    }, [i18n, userInfo]);
    function handleWindowSizeChange() {
        if (window.innerWidth <= 768)
            setIsMobile(true);
        else
            setIsMobile(false);
    }
    useEffect(function () {
        window.addEventListener('resize', handleWindowSizeChange);
        axios.get("".concat(miscApiPath, "/webappConfig")).then(function (res) {
            if (res.data.content)
                setAppConfig(res.data.content);
        });
        return function () {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    var invitedBy = localStorage.getItem('invitedBy');
    var goToCheckout = localStorage.getItem('referalId') && localStorage.getItem('priceId');
    useEffect(function () {
        if (goToCheckout && userInfo.email) {
            message.success('Please wait till we take you to subscription checkout page!');
            axios
                .post(stripeCheckoutApiPath, {
                price: localStorage.getItem('priceId'),
                client_reference_id: localStorage.getItem('referalId') || undefined,
                success_url: 'https://careerflow.ai/thank-you-premium',
                coupon: localStorage.getItem('coupon') || undefined,
            })
                .then(function (res) {
                window.location.assign(res.data.url);
                localStorage.removeItem('priceId');
            })
                .finally(function () { });
        }
    }, [goToCheckout, userInfo.email]);
    useEffect(function () {
        if (isPublicRoute())
            return;
        if ((!user || (!(user === null || user === void 0 ? void 0 : user.emailVerified) && (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) > 1694802600000)) &&
            !loading &&
            !window.location.href.includes('/extension/login')) {
            navigate('/login');
        }
        else if (userInfo.userDetailLoaded &&
            !userInfo.initialOnboardingDone &&
            //TODO uncomment later - Commented for testing
            (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) > 1703010600000) {
            console.log(window.location.pathname);
            if (window.location.pathname !== '/plan')
                navigate("/walkthrough");
        }
        else if (window.location.pathname === '/onboarding' &&
            (userInfo.jobBoardOnboarding === 10 ||
                userInfo.jobBoardOnboarding === true)) {
            navigate('/');
        }
        else if ((window.location.pathname === '/login' ||
            window.location.href.includes('/extension/login')) &&
            !!user &&
            ((user === null || user === void 0 ? void 0 : user.emailVerified) || (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) < 1694802600000)) {
            sendTokenToChromeExtension({
                user: user,
            })
                .then(function () {
                if (window.location.href.includes('/extension/login'))
                    window.close();
            })
                .catch(function (e) { return console.log(e); });
            navigate('/');
        }
    }, [
        userInfo.jobBoardOnboarding,
        user,
        loading,
        userInfo === null || userInfo === void 0 ? void 0 : userInfo.created,
        navigate,
        userInfo,
    ]);
    useEffect(function () {
        if (!user && !loading && isShouldLogout())
            logout();
    }, [loading, user]);
    useEffect(function () {
        var _a, _b;
        if ((!!user && newUser === false) ||
            (!!user && newUser && newUserSignupComplete)) {
            try {
                var jwt = (_b = (_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.stsTokenManager) === null || _b === void 0 ? void 0 : _b.accessToken;
                var decodedToken_1 = jwt_decode(jwt);
                axios.get(userRoleApiPath).then(function (userRoleGetResponse) {
                    localStorage.setItem('signupSource', userRoleGetResponse.data.signupSource);
                    setUserInfo(function (userInfo) {
                        return __assign(__assign(__assign({}, userInfo), { username: "".concat(userRoleGetResponse.data && userRoleGetResponse.data.fname, " ").concat(userRoleGetResponse.data && userRoleGetResponse.data.lname), fname: userRoleGetResponse.data && userRoleGetResponse.data.fname, lname: userRoleGetResponse.data && userRoleGetResponse.data.lname, email: userRoleGetResponse.data && userRoleGetResponse.data.email, userType: userRoleGetResponse.data.premiumUser ||
                                decodedToken_1.userType === 'premium'
                                ? 'premium'
                                : 'basic' }), userRoleGetResponse.data);
                    });
                });
                axios.get(userDetailApiPath).then(function (userDetailGetResponse) {
                    setUserInfo(function (userInfo) {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
                        return __assign(__assign(__assign({}, userInfo), { userDetailLoaded: true, profilePic: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].profilePhoto, jobBoardOnboarding: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].jobBoardOnboarding, questionnaire: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].questionnaire, phoneNo: (_a = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _a === void 0 ? void 0 : _a.phoneNo, gender: (_b = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _b === void 0 ? void 0 : _b.gender, dob: (_c = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _c === void 0 ? void 0 : _c.dob, invites: (_d = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _d === void 0 ? void 0 : _d.invites, removedSkills: ((_e = userDetailGetResponse.data[0]) === null || _e === void 0 ? void 0 : _e.removedSkills) || [], addedSkills: ((_f = userDetailGetResponse.data[0]) === null || _f === void 0 ? void 0 : _f.addedSkills) || [], country: (_g = userDetailGetResponse.data[0]) === null || _g === void 0 ? void 0 : _g.country, webAppLanguage: (_h = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _h === void 0 ? void 0 : _h.webAppLanguage }), (_j = userDetailGetResponse.data) === null || _j === void 0 ? void 0 : _j[0]);
                    });
                });
            }
            catch (err) {
                console.log('err :>> ', err);
            }
        }
    }, [newUser, newUserSignupComplete, user]);
    useEffect(function () {
        if (userInfo.jobBoardOnboarding === 10) {
            try {
                axios.get(userRoleApiPath).then(function (userRoleGetResponse) {
                    localStorage.setItem('signupSource', userRoleGetResponse.data.signupSource);
                    setUserInfo(function (userInfo) {
                        return __assign(__assign(__assign({}, userInfo), { username: "".concat(userRoleGetResponse.data && userRoleGetResponse.data.fname, " ").concat(userRoleGetResponse.data && userRoleGetResponse.data.lname), fname: userRoleGetResponse.data && userRoleGetResponse.data.fname, lname: userRoleGetResponse.data && userRoleGetResponse.data.lname, email: userRoleGetResponse.data && userRoleGetResponse.data.email }), userRoleGetResponse.data);
                    });
                });
                axios.get(userDetailApiPath).then(function (userDetailGetResponse) {
                    setUserInfo(function (userInfo) {
                        var _a, _b, _c, _d, _e, _f, _g;
                        return __assign(__assign(__assign({}, userInfo), { userDetailLoaded: true, profilePic: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].profilePhoto, jobBoardOnboarding: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].jobBoardOnboarding, questionnaire: userDetailGetResponse.data &&
                                userDetailGetResponse.data[0] &&
                                userDetailGetResponse.data[0].questionnaire, phoneNo: (_a = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _a === void 0 ? void 0 : _a.phoneNo, gender: (_b = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _b === void 0 ? void 0 : _b.gender, dob: (_c = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _c === void 0 ? void 0 : _c.dob, invites: (_d = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _d === void 0 ? void 0 : _d.invites, country: (_e = userDetailGetResponse.data[0]) === null || _e === void 0 ? void 0 : _e.country, webAppLanguage: (_f = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _f === void 0 ? void 0 : _f.webAppLanguage }), (_g = userDetailGetResponse.data) === null || _g === void 0 ? void 0 : _g[0]);
                    });
                });
            }
            catch (err) {
                console.log('err :>> ', err);
            }
        }
    }, [userInfo.jobBoardOnboarding]);
    var getUserDetails = useCallback(function () {
        axios.get(userRoleApiPath).then(function (userRoleGetResponse) {
            localStorage.setItem('signupSource', userRoleGetResponse.data.signupSource);
            setUserInfo(function (userInfo) {
                return __assign(__assign(__assign({}, userInfo), { username: "".concat(userRoleGetResponse.data && userRoleGetResponse.data.fname, " ").concat(userRoleGetResponse.data && userRoleGetResponse.data.lname), fname: userRoleGetResponse.data && userRoleGetResponse.data.fname, lname: userRoleGetResponse.data && userRoleGetResponse.data.lname, email: userRoleGetResponse.data && userRoleGetResponse.data.email }), userRoleGetResponse.data);
            });
        });
        axios.get(userDetailApiPath).then(function (userDetailGetResponse) {
            setUserInfo(function (userInfo) {
                var _a, _b, _c, _d, _e, _f, _g;
                return __assign(__assign(__assign({}, userInfo), { userDetailLoaded: true, profilePic: userDetailGetResponse.data &&
                        userDetailGetResponse.data[0] &&
                        userDetailGetResponse.data[0].profilePhoto, jobBoardOnboarding: userDetailGetResponse.data &&
                        userDetailGetResponse.data[0] &&
                        userDetailGetResponse.data[0].jobBoardOnboarding, questionnaire: userDetailGetResponse.data &&
                        userDetailGetResponse.data[0] &&
                        userDetailGetResponse.data[0].questionnaire, phoneNo: (_a = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _a === void 0 ? void 0 : _a.phoneNo, gender: (_b = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _b === void 0 ? void 0 : _b.gender, dob: (_c = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _c === void 0 ? void 0 : _c.dob, invites: (_d = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _d === void 0 ? void 0 : _d.invites, country: (_e = userDetailGetResponse.data[0]) === null || _e === void 0 ? void 0 : _e.country, webAppLanguage: (_f = userDetailGetResponse === null || userDetailGetResponse === void 0 ? void 0 : userDetailGetResponse.data[0]) === null || _f === void 0 ? void 0 : _f.webAppLanguage }), (_g = userDetailGetResponse.data) === null || _g === void 0 ? void 0 : _g[0]);
            });
        });
    }, []);
    var handleAcceptInviteByLink = useCallback(function () {
        var invitedBy = localStorage.getItem('invitedBy');
        if (invitedBy) {
            var payload = {
                firstName: userInfo.fname,
                lastName: userInfo.lname,
                email: userInfo.email,
                status: 'active',
            };
            axios
                .post("".concat(instituteAppApiPath, "/").concat(invitedBy, "/linkInvite"), payload)
                .then(function () {
                localStorage.removeItem('invitedBy');
                getUserDetails();
            });
        }
    }, [getUserDetails, userInfo.email, userInfo.fname, userInfo.lname]);
    useEffect(function () {
        if (invitedBy && userInfo.email) {
            handleAcceptInviteByLink();
        }
    }, [handleAcceptInviteByLink, invitedBy, userInfo.email]);
    var signup = function (authResponse, src) {
        if (src === void 0) { src = 'webapp'; }
        return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                // eslint-disable-next-line no-async-promise-executor
                return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                        var displayName, numberOfWords, firstname, lastname, userRoleRequestBody, e_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    displayName = authResponse.user.displayName;
                                    numberOfWords = displayName.split(' ').length;
                                    firstname = numberOfWords > 1
                                        ? displayName.split(' ').slice(0, -1).join(' ')
                                        : displayName;
                                    lastname = numberOfWords > 1 ? displayName.split(' ').slice(-1)[0] : '';
                                    userRoleRequestBody = {
                                        role: 'MENTEE',
                                        fname: firstname,
                                        lname: lastname,
                                        mentorStatus: '',
                                        profilePhoto: authResponse.user.photoURL || '',
                                        linkedin: '',
                                        signupSource: src,
                                        mentorRating: 0,
                                    };
                                    return [4 /*yield*/, axios.post(userRoleApiPath, userRoleRequestBody)];
                                case 1:
                                    _a.sent();
                                    localStorage.setItem('token', authResponse._tokenResponse.idToken);
                                    resolve(true);
                                    return [3 /*break*/, 3];
                                case 2:
                                    e_1 = _a.sent();
                                    console.log(e_1);
                                    reject(e_1);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); })];
            });
        });
    };
    var signInWithGoogleCall = function (src) {
        if (src === void 0) { src = 'webapp'; }
        return __awaiter(_this, void 0, void 0, function () {
            var authResponse, _a, isNewUser_1, idToken, error_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        analyticsEvent('Sign in with google', 'Login');
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        return [4 /*yield*/, signInWithGoogle()];
                    case 2:
                        authResponse = _b.sent();
                        _a = authResponse._tokenResponse, isNewUser_1 = _a.isNewUser, idToken = _a.idToken;
                        setAuthToken(idToken);
                        if (!isNewUser_1) return [3 /*break*/, 4];
                        setNewUser(true);
                        analyticsEvent('New User Sign In', 'SignIn');
                        return [4 /*yield*/, signup(authResponse, src)];
                    case 3:
                        _b.sent();
                        setNewUserSignupComplete(true);
                        getUserDetails();
                        _b.label = 4;
                    case 4:
                        localStorage.setItem('token', authResponse._tokenResponse.idToken);
                        sendTokenToChromeExtension({
                            jwt: idToken,
                            user: authResponse.user,
                        })
                            .then(function () {
                            message.success('Careerflow chrome extension also logged in.');
                            if (!isNewUser_1)
                                window.close(); // Don't close window if the new user has signed up
                        })
                            .catch(function (err) { return console.log(err); });
                        if (newUser) {
                            return [2 /*return*/];
                        }
                        getUserDetails();
                        return [3 /*break*/, 6];
                    case 5:
                        error_1 = _b.sent();
                        console.log;
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    var signupWithEmailAndPassword = function (email, password, fname, lname, src, setLoading, callback) { return __awaiter(_this, void 0, void 0, function () {
        var authResponse, token, userRoleRequestBody, error_2;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 4, 5, 6]);
                    setNewUser(true);
                    return [4 /*yield*/, registerWithEmailAndPassword(email, password)];
                case 1:
                    authResponse = _b.sent();
                    return [4 /*yield*/, authResponse.user.getIdToken()];
                case 2:
                    token = _b.sent();
                    sendEmailVerification(authResponse.user).catch(function (err) {
                        return console.log('err', err);
                    });
                    localStorage.setItem('token', token);
                    userRoleRequestBody = {
                        role: 'MENTEE',
                        fname: fname,
                        lname: lname,
                        mentorStatus: '',
                        profilePhoto: '',
                        linkedin: '',
                        signupSource: src,
                        mentorRating: 0,
                    };
                    return [4 /*yield*/, axios.post(userRoleApiPath, userRoleRequestBody)];
                case 3:
                    _b.sent();
                    setNewUserSignupComplete(true);
                    getUserDetails();
                    if (!((_a = authResponse.user) === null || _a === void 0 ? void 0 : _a.emailVerified)) {
                        sendEmailVerification(authResponse === null || authResponse === void 0 ? void 0 : authResponse.user).then(function () {
                            if (callback)
                                callback();
                        });
                    }
                    return [3 /*break*/, 6];
                case 4:
                    error_2 = _b.sent();
                    message.error("".concat(error_2).replace('FirebaseError: Firebase:', ''));
                    navigate('/login');
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var signinWithEmailAndPassword = function (email, password, cb) { return __awaiter(_this, void 0, void 0, function () {
        var result, authResponse, token, error_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 4, , 5]);
                    return [4 /*yield*/, fetchSignInMethodsForEmail(auth, email)];
                case 1:
                    result = _c.sent();
                    if (result.includes('google.com')) {
                        message.error('Please use the Continue With Google option to login');
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, logInWithEmailAndPassword(email, password)];
                case 2:
                    authResponse = _c.sent();
                    if (!((_a = authResponse.user) === null || _a === void 0 ? void 0 : _a.emailVerified) &&
                        (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) > 1694802600000) {
                        cb();
                    }
                    if (((_b = authResponse.user) === null || _b === void 0 ? void 0 : _b.emailVerified) || (userInfo === null || userInfo === void 0 ? void 0 : userInfo.created) < 1694802600000)
                        sendTokenToChromeExtension({
                            user: authResponse.user,
                        })
                            .then(function () {
                            message.success('Careerflow chrome extension also logged in.');
                            window.close();
                        })
                            .catch(function (err) { return console.log(err); });
                    return [4 /*yield*/, authResponse.user.getIdToken()];
                case 3:
                    token = _c.sent();
                    localStorage.setItem('token', token);
                    return [3 /*break*/, 5];
                case 4:
                    error_3 = _c.sent();
                    console.log("".concat(error_3));
                    if ("".concat(error_3).includes('user-not-found')) {
                        message.error('User not found! Please use the sign up option to create an account.');
                    }
                    else {
                        message.error("".concat(error_3).replace('FirebaseError: Firebase:', ''));
                    }
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    return (_jsx(AppContext.Provider, __assign({ value: {
            upgrade: upgrade,
            user: user,
            setUpgrade: setUpgrade,
            signInWithGoogleCall: signInWithGoogleCall,
            userInfo: userInfo,
            authToken: authToken,
            newUser: newUser,
            appConfig: appConfig,
            newUserSignupComplete: newUserSignupComplete,
            institute: institute,
            setNewUser: setNewUser,
            isMobile: isMobile,
            setUserInfo: setUserInfo,
            isExtensionInstalled: isExtensionInstalled,
            setIsExtensionInstalled: setIsExtensionInstalled,
            loading: loading,
            handleAcceptInviteByLink: handleAcceptInviteByLink,
            getUserDetails: getUserDetails,
            signupWithEmailAndPassword: signupWithEmailAndPassword,
            signinWithEmailAndPassword: signinWithEmailAndPassword,
        } }, { children: children })));
}
export default UserProvider;

import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import {
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  GoogleAuthProvider,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { getFunctions } from 'firebase/functions';
import { getPerformance } from 'firebase/performance';
import { connectStorageEmulator, getStorage, ref } from 'firebase/storage';
const {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} = require('firebase/app-check');

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// eslint-disable-next-line no-restricted-globals
self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.REACT_APP_DEBUG_TOKEN;

const isDev = () => process.env.REACT_APP_ENVIRONMENT === 'LOCAL';

const app = initializeApp(firebaseConfig);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    process.env.REACT_APP_RECAPTCHA_TOKEN
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});
const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();

const storage = getStorage(app);
const perf = getPerformance(app);
// trace(perf, "measuringX")

const analytics = getAnalytics(app);
// logEvent(analytics)

const functions = getFunctions(app);

if (isDev()) {
  connectAuthEmulator(auth, 'http://localhost:9099');
  connectStorageEmulator(storage, 'localhost', 9199);
}

const signInWithGoogle = () => signInWithPopup(auth, googleProvider);

const logInWithEmailAndPassword = (email, password) =>
  signInWithEmailAndPassword(auth, email, password);

const registerWithEmailAndPassword = (email, password) =>
  createUserWithEmailAndPassword(auth, email, password);

const logout = async () => {
  indexedDB.deleteDatabase('CareerflowDB');
  const companyWant = localStorage.getItem('Company-want');
  const ContactWant = localStorage.getItem('Contacts-want');
  const addNewSection = localStorage.getItem('Add-New-Section-want');
  localStorage.clear();
  localStorage.setItem('Company-want', companyWant);
  localStorage.setItem('Contacts-want', ContactWant);
  localStorage.setItem('Add-New-Section-want', addNewSection);
  await signOut(auth);
  window.location.href = '/';
};

const triggerResetEmail = async (email) => {
  await sendPasswordResetEmail(auth, email);
  console.log('Password reset email sent');
};

export {
  analytics,
  appCheck,
  auth,
  functions,
  logInWithEmailAndPassword,
  logout,
  perf,
  registerWithEmailAndPassword,
  signInWithGoogle,
  storage,
  triggerResetEmail,
  ref as storageRef,
};

import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BugOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import styles from './Fallback.module.css';
import err from './images/download.png';
function Fallback() {
    return (_jsxs("div", __assign({ style: { textAlign: 'center', paddingTop: '15rem' } }, { children: [_jsx("img", { src: err, height: 100, alt: "call", style: { marginRight: '4px', marginBottom: '5rem' } }), _jsx("h1", { children: "Oops, something went wrong." }), _jsx("p", { children: "Please try again later or contact support." }), _jsx("div", __assign({ style: { marginTop: 24 } }, { children: _jsxs("div", __assign({ style: { display: 'flex', justifyContent: 'center' } }, { children: [_jsxs(Button, __assign({ className: "".concat(styles.createbtn, " btnContainer"), onClick: function () { return window.location.reload(); } }, { children: [_jsx(ReloadOutlined, {}), " Refresh"] })), _jsxs(Button, __assign({ className: "".concat(styles.createbtn1, " btnContainer"), onClick: function () { return window.open('https://forms.gle/XLZZDXH1vfAaxYS19'); } }, { children: [_jsx(BugOutlined, {}), " Report an Issue"] }))] })) }))] })));
}
export default Fallback;

import { __assign } from "tslib";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import styles from './MainLoader.module.css';
import logo from '../../images/logo.png';
function MainLoader() {
    return (_jsxs("div", __assign({ style: {
            height: '100vh',
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            gap: '1.5rem',
            alignItems: 'center',
        } }, { children: [_jsxs("div", __assign({ style: {
                    display: 'flex',
                    gap: '0.5rem',
                    fontSize: '30px',
                    fontWeight: '500',
                    justifyContent: 'center',
                    alignItems: 'center',
                } }, { children: [_jsx("img", { src: logo, alt: "logo", height: 30 }), _jsx("div", { children: "Careerflow" })] })), _jsx("div", __assign({ className: styles.loaderContainer }, { children: _jsx("div", { className: styles.progressBar }) }))] })));
}
export default MainLoader;
